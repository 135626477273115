var homepageslider = false;
var homeswiper = null;

$(document).mousemove(function(event){
    initDSSlider();
});
window.onscroll = function(event) {
    initDSSlider();
};

function initDSSlider(){
    if(homepageslider == false){
        var sliderspeed = $('#homepage-slider').data('speed');
        var sliderloop = $('#homepage-slider').data('loop');
        var sliderpause = $('#homepage-slider').data('pause');

        $('#homepage-slider').slick(
            {
                slidesToScroll: 1,
                slidesToShow: 1,
                mobileFirst     : true,
                swipe:true,
                arrows: false,
                dots: true,
                pauseOnHover: sliderpause,
                infinite: sliderloop,
                autoplay: true,
                autoplaySpeed: sliderspeed,
                prevArrow:"<button type=\"button\" class=\"btn btn-link slick-prev slick-arrow\"><svg width=\"10.414\" height=\"18.828\" viewBox=\"0 0 10.414 18.828\"><path d=\"M691.159,8403.762l-8,8,8,8\" transform=\"translate(-682.159 -8402.348)\" fill=\"none\" stroke=\"#fff\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\"/></svg></button>",
                nextArrow:"<button type=\"button\" class=\"btn btn-link slick-next slick-arrow\"><svg width=\"10.414\" height=\"18.828\" viewBox=\"0 0 10.414 18.828\"><path d=\"M691.159,8403.762l-8,8,8,8\" transform=\"translate(692.573 8421.176) rotate(180)\" fill=\"none\" stroke=\"#fff\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\"/></svg></button>"
            }
        );
        homepageslider = true;
    }
}